.sign-up {

    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.font-info {
    color: #7B8499;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.register-button {
    display: flex;
    width: 315px;
    height: 59px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.error-info {
    color: red;
    font-size: 12px;
    border-top: 0ch;
}