.nav-bar-tabs img,
.nav-bar-tabs p {
    transition: all 0.1s linear;
}

.nav-bar-tabs:hover img,
.nav-bar-tabs:hover p {
    transform: translateX(5px)
}

.nav-bar-tabs > p {
    font-size: 25px;
}

.nav-bar-tabs > img {
    height: 25px;
}

.invite-tab > img {
    height: 20px;
}


@media (max-width: 1400px) {
    .assessment-tab {
        min-width: 200px
    }

    .search-bar {
        margin-left: 100px;
    }
}
