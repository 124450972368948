
.main_container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.right_section_container{
   display: flex;
   align-items: center;
}

.main_img{
    max-width: 100%;
    height: 100vh;
}

.main_img_div{
    display: flex !important;
    justify-content: center !important;
}

.description_heading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.option-btn{
    border-radius: 0.5rem;
}

.option-icon{
    height: 9vh;
}

.option-inactive{
    border: 2px solid #000000;
    background: #FFFFFF;
}

.option-active{
    border: 2px solid #43CCC4;
    background: #EEFFFE;
}

.pointer {
    cursor: pointer;
}

@media (max-width: 768px) {
    .main_container {
        flex-wrap: wrap;
    }

}