.careers-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.careers-introduction {
    width: 80%;
    padding: 30px;
    background: #e6f7ff; /* Light blue background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.careers-introduction p {
    margin: 0 0 10px 0;
}

.careers-section {
    width: 80%;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.careers-section h2 {
    color: #ff6f61;
    margin-bottom: 10px;
}

.careers-section p {
    color: #333;
    line-height: 1.6;
}

.careers-section ul {
    padding-left: 20px;
}

.careers-section ul li {
    margin-bottom: 10px;
}

.careers-introduction p:first-of-type p:first-of-type {
    margin-top: 0;
}

.careers-introduction p:last-of-type p:last-of-type {
    margin-bottom: 0;
}
