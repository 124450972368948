.calender-date-num {
    transition: all 0.1s linear;
    border-radius: 20px;
}

.calender-date-num:hover {
    background-color: rgb(222, 222, 222);
}

.time-zone-button {
    background-color: white;
    border: 1.5px solid rgb(169, 169, 169);
}

.selected-date-num { 
    background-color: #43CCC4;
    color: white;
}

.selected-date-num:hover {
    background-color: #43CCC4;
    color: white;
}

.time-zone-button:hover {
    background-color: rgb(232, 232, 232);
}

.time-zone-selected {
    background-color: #43CCC4;
    color: white;
}

.time-zone-selected:hover {
    background-color: #43CCC4;
    color: white;
}

textarea {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
}

.cancel-button-assessment-page,
.submit-button-assessment-page,
.cancel-address-button,
.submit-address-button,
.open-invitation-button,
.done-button {
    background-color: white;
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: 1.5px solid rgb(221, 221, 221);
    margin-left: 20px;
    transition: all 0.2s linear;
}

.cancel-address-button,
.submit-address-button {
    background-color: white;
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: 1.5px solid rgb(221, 221, 221);
    margin-left: 0px;
    transition: all 0.2s linear;
    
}

.submit-button-assessment-page,
.submit-address-button,
.done-button {
    background-color: #43CCC4;
    color: white;
}

.schedule-completion {
    box-shadow: 0px 5px 5px 5px rgb(214, 214, 214);
}

.overlay-gray {
    background-color: rgba(128, 128, 128, 0.358);
}

.submit-button-assessment-page:hover,
.submit-address-button:hover,
.done-button:hover {
    background-color: #39a59e;
}

.cancel-button-assessment-page:hover,
.cancel-address-button:hover,
.open-invitation-button:hover {
    background-color: rgb(228, 228, 228);
}

.schedule-assessment-page {
    box-shadow: 0px 5px 5px 5px rgb(210, 210, 210);
    background-color: white;
    border-radius: 30px;
}

.address_line > input,
.city-and-state-input > input,
.postal_code_input > input  {
    border: 2px solid rgb(220, 220, 220);
    padding: 5px;
    border-radius: 10px;
}

.congratulations-box {
    border: 2px solid rgb(229, 229, 229)
}

