.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-image: url('../../assets/images/group-pic.png');
    background-position: -28px -50px;
    background-size: 802.08px 1006.2px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    overflow: hidden;
}

.parent-container {
    position: relative;
}

.logo {
    position: absolute;
    width: 347px;
    height: 78.43px;
    top: 113px;
    left: 904px;
}

.header {
    position: absolute;
    /*width: 198px;*/
    /*height: 39px;*/
    top: 317px;
    left: 912px;
    font-family: 'Arial', sans-serif; /* Assuming Arial, adjust as necessary */
    font-size: 28px; /* Adjust as needed to match the appearance */
    font-weight: bold; /* This makes the text bold */
    color: #000; /* Assuming the text is black, adjust as necessary */
}

.accountPrompt {
    width: 260px;
    height: 21px;
    position: absolute;
    top: 356px;
    left: 914px;
    display: flex;
    flexDirection: row;
    alignItems: center;
    fontFamily: 'Poppins', sans-serif;
    fontSize: '14px';
    lineHeight: '21px';
    letterSpacing: '0em';
    textAlign: 'left';
}

.accountPromptText {
    fontWeight: 700; /* Normal text */
    marginRight: '5px'; /* Add some space between the text and the link */
    text-align: left; /* Align text to the left */
    margin-bottom: 4px;
    fontFamily: sans-serif;
}

.accountPromptLink {
    fontWeight: 700; /* Bold text for 'Create Now!' */
    textDecoration: 'none'; /* Removes underline from the link */
    color: #43CCC4; /* Assuming you want the link to have the gradient color */
    hover: underline cursor-pointer;
}