.container {
  display:flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.icon {
  width: 150px; /* Adjust size as needed */
}
.error-message {
  color: red;
  margin-top: 1rem;
}
.input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
}

.input-with-icon input {
  width: 100%;
  padding-right: 45px; /* Add padding to avoid text underneath icon */
}

.input-with-icon img {
  position: absolute;
  cursor: pointer;
  right: 20px; /* Adjust based on padding */
  width: 20px;
  height: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

.modal {
  background: white;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 50px;
  width: 60%;
  max-width: 650px;
  border: 1px solid #3B3A3A;
}

