
.container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.right-section{
   display: flex;
   align-items: center;
}

.main-img{
    max-width: 100%;
    height: 100vh;
}

.alignment{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.option-btn{
    border-radius: 0.5rem;
}

.option-icon{
    height: 9vh;
}

.option-inactive{
    border: 2px solid #000000;
    background: #FFFFFF;
}

.option-active{
    border: 2px solid #43CCC4;
    background: #EEFFFE;
}

.pointer {
    cursor: pointer;
}
