.about-section-list {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.about-section {
    width: 80%;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.about-section h2 {
    color: #ff6f61;
    margin-bottom: 10px;
}

.about-section p {
    color: #333;
    line-height: 1.6;
}
