@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins";
}

.login-button-nav,
.signup-button-nav {
    border: 2px solid black;
    font-size: 18px;
    font-weight: bold;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 40px;
    margin-left: 5px;
    transition: all 0.2s linear;
}

body {
    overflow-x: hidden;
}

.insights-section .text-section-left-insights,
.insights-section img {
    transition: all 0.8s linear;
}
.text-section-left-insights {
    background-color: #DFEFFE;
}

.insights-section .out-of-bounds-tleft {
    transform: translate(-3000px, -500px);
}

.insights-section .out-of-bounds-bright {
    transform: translate(3000px, 500px);
}

.incentive-deployment-header,
.incentive-deployment-image {
    transition: all 0.8s linear;
}

.start-today-button {
    width: 160px;
    height: 43px;
    border: 2px solid #2B3CD7;
    font-weight: bold;
    border-radius: 20px;
    font-size: 18px;
    background-color: #2B3CD7;
    color: white;
    transition: all 0.1s linear;
}

.start-today-property-button {
 width: 150px;
 background-color: #2B3CD7;
 align-self: baseline;
 padding: 10px;
 font-weight: bold;
 color: white;
 border-radius: 20px;
 margin-top: 20px;
 transition: all 0.1s linear;
}

.speak-expert-button {
    padding: 0px 15px 0px 15px;
    height: 43px;
    border: 2px solid #2B3CD7;
    font-weight: bold;
    border-radius: 20px;
    font-size: 18px;
    transition: all 0.1s linear;
    color: #2B3CD7;
}

.nav-bar-dropdown ul {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    flex-direction: column;
    box-shadow: 0px 2px 10px 2px rgba(93, 93, 93, 0.225);
}

.select-section button {
    padding: 15px;
    border-radius: 30px;
}

.select-section button:hover {
    background-color: rgba(255, 255, 255, 0.495);
}

.nav-bar-dropdown ul li {
    text-align: left;
    font-weight: 500
}

.nav-bar-dropdown ul {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 2px 10px 2px rgba(93, 93, 93, 0.225);
}

.select-section button {
    padding: 15px;
    border-radius: 30px;
}


.nav-bar-dropdown ul li {
    text-align: left;
    font-weight: 500
}

.speak-expert-button:hover {
    background-color: rgba(255, 255, 255, 0.808);
}

.start-today-button:hover {
    background-color: black;
}

.last-section {
    background-color: #DFEFFE;
}

.login-button-nav:hover {
    background-color: rgb(237, 237, 237);
}

.out-of-bounds-right {
    transform: translateX(2000px)
}


.out-of-bounds-left {
    transform: translateX(-2000px)
}

.blue-box-people {
    background-color:#DFEFFE
}

.signup-button-nav {
    background-color: #2B3CD7;
    color: white;
    border: 0px solid white;
}

.signup-button-nav:hover,
.start-today-property-button:hover {
    background-color: #2b3cd773;
}

.user-select-none {
    user-select: none;
}

li.nav-bar-dropdown:hover ul {
    display: flex;
}

.side-arrow-nav {
    transition: all 0.1s linear;
    width: 20px;
    height: 20px;
}

li.nav-bar-dropdown:hover div .side-arrow-nav {
    transform: rotate(180deg);
}

.nav-bar-dropdown ul:hover {
    display: flex;
}


.select-section {
    background-color: #878ecd;
    color: white;
    border-radius: 40px;
    display: flex;
    font-size: 18px;
    font-weight: bold;
}


.selected-mode {
    background-color: white;
    color: black;
    margin: 0px;
    border-radius: 40px; 
}

.select-section .selected-mode:hover {
    background-color: white;
}

.first-blue-section {
    background-color: #2B3CD7;
}

.nav-bar {
    background-color: white;
    z-index: 100;
}

.property-owner-sign-up {
    background:linear-gradient(to right, #8c8ee9, #7b7dec);
    width: 120px;
    height: 40px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    transition: all 0.1s linear;
}

.property-owner-sign-up:hover {
    transform: scale(1.1);
}

.schedule-now-button {
    max-width: 200px;
    
    border: 2px solid rgb(123, 123, 123);
    padding: 6px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    transition: all 0.1s linear;
}

.schedule-now-button:hover {
    background-color: rgb(123, 123, 123);
    color: white;
}

.sign-up-button-IP {
    width: 120px;
    height: 47px;
    border-radius: 30px;
    background: linear-gradient(to right, rgb(171, 171, 171), rgb(89, 89, 89));
    font-weight: bold;
    font-size: 18.5px;
    transition: all 0.1s linear;
    align-self: flex-start;
}

.hero-section-2 .hero-text p {
    font-weight: 500;
}

.header-providers {
    overflow: hidden;
}

.header-animation {
    animation: header-animation 5s linear infinite;
}

@keyframes header-animation {
    0% {
        transform: translateY(0px);
    }
    30% {
        transform: translateY(0px);
    }
    33% {
        transform: translateY(-60px);
    }
    63% {
        transform: translateY(-60px);
    }
    66% {
        transform: translateY(-120px);
    }
    97% {
        transform: translateY(-120px);
    }
    100% {
        transform: translateY(0px);
    }
}

.incentive-card-button {
    width: 170px;
    height: 40px;
    border: 2px solid #2B3CD7;
    border-radius: 20px;
    font-weight: 500;
    transition: all 0.1s linear;
    color: #2B3CD7;
}

.incentive-card-button:hover {
    background-color: rgb(238, 238, 238);
    transform: scale(1.1);
}

.incentive-card {
    gap: 5px;
    padding: 20px;
    box-shadow: 0px 2px 10px 5px rgb(207, 207, 207);
    border-radius: 50px;

}

.blue-wrapper {
    background-color: #DFEFFE;
    padding: 50px; 
    gap: 50px;
    border-radius: 30px;;
}

.blue-box-computer-person {
    transition: all 0.3s linear;
    top: 0px;
    left: 0px;
    background-color: #2B3CD7;
}

.move {
    transform: translate(60px, -60px);
}

.incentive-card .icon {
    background-color: #2B3CD7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-up-button-IP:hover {
    transform: scale(1.05);
    box-shadow: 0px 2px 2px 2px rgba(128, 128, 128, 0.405);
}

.hero-section-2 {
    height: 82vh;
    background: linear-gradient(to right, #535776, #050932);
}

.blue-box-commercial {
    height: 600px;
    width: 1050px;
    left: -200px;
    background-color: #DFEFFE;
    z-index: 0;
    position: absolute;
    border-radius: 50px;
    top:-120px;
}

.commercial-or-residential .text-right-section {
    transform: translateY(-100px);
}
