/* AccountVerification.module.css */

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-image: url('../../assets/images/group-pic.png');
    background-position: -28px -50px;
    background-size: 802.08px 1006.2px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    overflow: hidden;
}

.logo {
    position: absolute;
    width: 347px;
    height: 78.43px;
    top: 113px;
    left: 904px;
}

.header {
    position: absolute;
    width: 251px;
    height: 38px;
    top: 263px;
    left: 960px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.subtitle {
    position: absolute;
    width: 309px;
    height: 42px;
    top: 316px;
    left: 960px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.inputCode {
    position: absolute;
    width: 315px;
    height: 62px;
    top: 391px;
    left: 960px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    padding: 10px;
    outline: none;
    text-align: center;
}

/* AccountVerification.module.css */
.verifyButton {
    position: absolute;
    width: 315px;
    height: 59px;
    top: 512px;
    left: 960px;
    border-radius: 10px;
    border: none;
    background-color: #43CCC4; /* Active color */
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.disabledButton {
    background-color: grey; /* Disabled state color */
    cursor: not-allowed;
}

.arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 267px;
    left: 862px;
    cursor: pointer; /* To indicate the image is clickable */
}

.resendText {
    position: absolute;
    width: 127px;
    height: 24px;
    top: 600px;
    left: 1054px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    cursor: default;
}
