.container-div {
  display:flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 40vw;
}
.icon {
  width: 5rem !important; /* Adjust size as needed */
}
.error-message {
  color: red;
  margin-top: 1rem;
}

.form-container{
  display: flex;
  justify-content: center;
}